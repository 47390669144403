export const Terms = ({showBorders=true}) => {

    const informationPoint = (title, text) => {
        return (
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-bold text-gray-900">{title}</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0" dangerouslySetInnerHTML={{ __html: text}}></dd>
            </div>
        )
    }

    return (
        <div className=''>
            <div className="overflow-hidden screen-small:bg-white screen-small:shadow sm:rounded-lg max-w-[900px] mx-5 screen-small:my-[50px] screen-small:ml-[50px]">
                <div className="px-4 py-6 sm:px-6">
                    <h3 className="text-2xl font-semibold leading-7 text-gray-900">Terms</h3>
                    <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Terms of service</p>
                    <div className='mt-4'>
                        Welcome to the Carzelle A/S Partner Portal, our exclusive platform for car dealerships. Please review these terms carefully before using our platform. By accessing or using our services, you accept and agree to these terms.
                    </div>
                </div>
                <div className={`${showBorders ? 'border-t border-gray-100': ''}`}>
                    <dl className="divide-y divide-gray-100">
                        {informationPoint('Fast Delivery', 'Vehicles marked as "Fast Delivery" are in stock and available for immediate shipment.')}
                        {informationPoint('Confirmed Vehicles', 'Vehicles labeled "Confirmed" have been verified, with Carzelle A/S as the registered owner.')}
                        {informationPoint('Partner Listing Vehicles', 'Vehicles marked as "Partner Listing" are displayed in collaboration with third-party vendors, and availability may vary. Carzelle A/S will secure these vehicles on your behalf after confirmation, and payment will be required immediately.')}
                        {informationPoint('Payment Terms', '<b>For Fast Delivery & Confirmed Vehicles:</b><br/>Payment is due within three days after receiving the order confirmation.</br><br/><b>For Partner Listing Vehicles:</b><br/>Payment is due immediately upon order confirmation.')}
                        {informationPoint('Credit Limit & Purchase Cap', 'Carzelle A/S reserves the right to set credit limits or restrict the number of purchases for any buyer at its sole discretion. These limits may vary and are based on internal assessments and policies. Carzelle A/S may adjust or revoke credit limits at any time without prior notice.')}
                        {informationPoint('Cancellation Policy', 'All purchases are final. If a cancellation is requested, the purchase amount remains due in full. Carzelle A/S will assist in reselling the vehicle, and if the resale price is lower than the original purchase price, the difference will be deducted from the refunded amount.')}
                        {informationPoint('Cosmetic Condition', 'Each listing provides details on the vehicle\'s cosmetic condition, including photos of any visible damages. An allowance of up to 450 EUR applies to undisclosed damages unless otherwise specified. If the vehicle’s condition does not meet expectations, submit a claim to <a href="mailto:info@carzelle.com" class="underline">info@carzelle.com</a> within 24 hours of receiving the vehicle. If transport damage is suspected, submit a claim immediately to the transport provider.')}
                        {informationPoint('Certificate of Conformity (COC)', 'All vehicles come with a Certificate of Conformity (COC).')}
                        {informationPoint('Accompanying Accessories', 'We strive to include essential accessories, such as two keys, a service book, and charging cables, when possible. However, these items may not be available for all vehicles.')}
                        {informationPoint('Service & Maintenance Records', 'Service and maintenance records are included when available, but cannot be guaranteed for every vehicle. Service and maintenance is not guaranteed with all vehicles.')}
                        {informationPoint('Equipment Discrepancies', 'If any listed equipment does not match upon delivery, you may submit a claim for a price adjustment. Any equipment not listed should not be assumed to be included. If you do not accept the adjustment, Carzelle A/S reserves the right to cancel the purchase instead of settling the claim.\n')}
                        {informationPoint('Transport Options', '<b>For Non-Danish Buyers:</b><br/>Vehicles can be picked up at our location or shipped to you upon request. Contact us for pricing.<br/><br/><b>For Danish Buyers:</b><br />Vehicles can be picked up or transported to your location through Dansk Autologik at competitive rates. Carzelle A/S does not manage or oversee these transport services and is not liable for any issues. Claims related to transport should be directed to Dansk Autologik A/S.')}
                        {informationPoint('Restrictions on Pre-Purchase Listings', 'Vehicles listed on Carzelle A/S may not be posted for sale on any marketplace until the purchase is complete.')}
                        {informationPoint('Suggested Price Calculation', 'The prices provided are applicable solely for the Danish market. Carzelle does not assume any responsibility for the accuracy or reliability of the suggested prices provided. It is the responsibility of the user to independently verify the suggested prices and any associated taxes or fees before making any purchasing decisions.')}
                        {informationPoint('Claims & Compensation', 'Claims must be submitted within 24 hours of vehicle delivery. A deductible of 450 EUR applies to any compensation (deducted from the total amount). Any costs incurred before claim resolution are not covered. Carzelle A/S reserves the right to cancel the purchase rather than settle a claim.')}
                        {informationPoint('Liability Disclaimer', 'Carzelle A/S is not liable for unexpected delays or issues in the vehicle acquisition process. Users should exercise due diligence in all transactions. Contact us at <a href="mailto:info@carzelle.com" class="underline">info@carzelle.com</a> for assistance.')}
                        {informationPoint('Value Added Tax (VAT)', 'Vehicles sold to buyers outside Denmark are VAT-exempt unless VAT has already been paid.')}
                        {informationPoint('Seller Information', 'Carzelle A/S</br>Bakketoften 3, 8541 Skødstrup, Denmark</br>VAT: DK44210223</br>Email: <a href="mailto:info@carzelle.com" class="underline">info@carzelle.com</a>')}
                        {informationPoint('Legal Jurisdiction', 'Any legal matters shall be resolved in the Court of Aarhus, Denmark.')}
                    </dl>
                </div>
            </div>
            <div className='h-[100px]' ></div>
        </div>
    )
}