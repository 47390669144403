import React, {useEffect, useState} from "react";
import {CustomerManagerInformationBox} from "../../../Components/Information/CustomerManagerInformationBox";
import {InitControlValueData} from "../../../Components/Navigation/CarFilterBar";
import {axiosInstance} from "../../../Api/AxiosConfig";
import {VATIcon} from "../../../Components/Cars/VATIcon";
import {Waiting} from "../../../Components/System/Waiting";
import {CarSearchBox} from "../../../Components/Navigation/CarSearchBox";
import {BrandPillSelection} from "../../../Components/Brand/BrandPillSelection";
import {CarFilterMobile} from "../../../Components/Navigation/CarFilterMobile";
import {CarSortMobile} from "../../../Components/Navigation/CarSortMobile";
import {ChevronDownIcon, ChevronUpIcon} from "@heroicons/react/24/solid";
import {CarMobileCard} from "../../../Components/Cars/CarMobileCard";
import {ShowroomMode} from "../../../Components/Navigation/ShowroomMode";
import {useLocation} from "react-router-dom";
import {useGlobalDataContext} from "../../../GlobalState/GlobalDataProvider";
import {getCookie} from "../../../Hooks/UseGenericFunctions";
import {Tooltip} from "@mantine/core";
import {MobileLayout} from "../../../Components/Layouts/MobileLayout";


export const CarList = () => {
    const [data, setData] = useState();
    const [dataSold, setDataSold] = useState();
    const [makes, setMakes] = useState();
    const [updateData, setUpdateData] = useState(false);
    const [sortByField, setSortByField] = useState('designation');
    const [filterData, setFilterData] = useState(InitControlValueData);
    const [selectedCarId, setSelectedCarId] = useState(null);
    const [showFilterOptions, setShowFilterOptions] = useState(false);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const carLink = searchParams.get("carLink");
    const [showSearch, setShowSearch] = useState(carLink !== null);
    const {showroomMode, setShowroomMode} = useGlobalDataContext();

    axiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + getCookie('CARZELLE_API_TOKEN');

    const filterList = (data) => {
        let dataToFilter = data.filter((car) => car.buy_status !== 'listing');

        if (filterData.make_id !== null) {
            dataToFilter = dataToFilter.filter((car) => car.make_id.toString() === filterData.make_id);
        }

        if (filterData.in_basket) {
            dataToFilter = dataToFilter.filter((car) => car.in_basket !== null);
        }

        if (filterData.search_text !== '') {
            dataToFilter = dataToFilter.filter((car) => (car.designation + car.vin_no + car.car_id + car.info_line_1 + car.info_line_2).toLowerCase().includes(filterData.search_text.toLowerCase()));
        }

        if (filterData.hide_sold) {
            dataToFilter = dataToFilter.filter((car) => car.is_sold === null);
        }

        if (filterData.buy_status) {
            dataToFilter = dataToFilter.filter((car) => car.buy_status === 'fast_delivery');
        }

        if (sortByField !== 'price') {
            dataToFilter = dataToFilter.sort((a, b) => {
                if (a[sortByField] < b[sortByField]) {
                    return -1;
                } else if (a[sortByField] > b[sortByField]) {
                    return 1;
                } else {
                    // If the primary sortByField values are equal, fall back to sorting by car_id
                    return a.hash - b.hash;
                }
            });
        } else {
            dataToFilter = dataToFilter.sort((a, b) => {
                if (parseInt(a.price) < parseInt(b.price)) {
                    return -1;
                } else if (parseInt(a.price) > parseInt(b.price)) {
                    return 1;
                } else {
                    // If the prices are equal, fall back to sorting by hash
                    return a.hash - b.hash;
                }
            });
        }
        return dataToFilter;
    }

    useEffect(() => {
        axiosInstance
            .get('/shop/buy-list')

            .then((response) => {
                setMakes(response.data.makes);
                setData(response.data.list);
                setDataSold(response.data.sold);

            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    }, [updateData]); // eslint-disable-line react-hooks/exhaustive-deps

    if (data) {
        return (
            <MobileLayout showroomMode={showroomMode} enableSearch={true} showSearch={showSearch} setShowSearch={setShowSearch} updateData={updateData} setUpdateData={setUpdateData}>
                <div>
                    <div className={`sticky top-[100px] left-0 z-[10] pb-4 border-t-2 border-t-background-color border-b-4 bg-background-color screen-small:block ${showFilterOptions ? 'shadow-lg' : ''} `} style={{display: showFilterOptions ? "shadow-xl" : ""}}>
                        {showSearch &&
                            <div className={`flex ml-3 mr-3 screen-small:ml-6 screen-small:mr-4 items-center mt-4 max-w-[900px]`}>
                                <CarSearchBox fnSearchValueChange={(filterValues) => setFilterData(filterValues)}/>
                            </div>
                        }

                        <div className="ml-6 flex items-center mt-4 text-xs text-gray-500 cursor-pointer hover:underline">
                            <div style={{display: showFilterOptions ? 'none' : 'flex'}} onClick={() => setShowFilterOptions(true)}>
                                <div className="flex items-center">Filter and sort options <ChevronDownIcon className="w-3 ml-3"/></div>
                            </div>
                            <div style={{display: showFilterOptions ? 'flex' : 'none'}} onClick={() => setShowFilterOptions(false)}>
                                <div className="flex items-center">Hide filter and sort options <ChevronUpIcon className="w-3 ml-3"/></div>
                            </div>
                        </div>

                        {showFilterOptions &&
                            <div className="z-10 ml-6">
                                <div className="flex items-center mt-4">
                                    <Tooltip label="Show/hide prices" position="bottom" transition="pop" openDelay={1500}>
                                        <ShowroomMode value={showroomMode} changeValue={(value) => setShowroomMode(value)}/>
                                    </Tooltip>
                                </div>
                                <div className='flex items-center mt-4'>
                                    <BrandPillSelection makes={makes} fnFilterChange={(filterValues) => setFilterData(filterValues)}/>
                                </div>

                                <div className='flex items-center mt-2'>
                                    <CarFilterMobile fnFilterChange={(filterValues) => setFilterData(filterValues)}/>
                                </div>

                                <div className='flex items-center mt-4'>
                                    <CarSortMobile fnSortChange={(value) => setSortByField(value)} activeSortIndex={1}/>
                                </div>
                            </div>
                        }
                    </div>

                    <div id="mobile_card_container" className="mb-8 mx-2">
                        {filterList(data).map((car) =>
                            (
                                <div id={'car_mobile_card_' + car.hash} key={'car_' + car.hash}>
                                    <div key={'car_mobile_card_' + car.hash} className=''>
                                        <CarMobileCard car={car} fnUpdate={() => setUpdateData(!updateData)} showPrice={!showroomMode} selectedCarId={selectedCarId} setSelectedCarId={setSelectedCarId}/>
                                    </div>
                                </div>
                            ))
                        }

                        <div className="font-bold text-xl mb-4 mt-8" style={{display: filterList(dataSold).length > 0 ? "block" : "none"}}>Cars sold today</div>
                        {filterList(dataSold).map((car) =>
                            (
                                <div id={'car_mobile_card_' + car.hash} key={'car_' + car.hash}>
                                    <div key={'car_mobile_card_' + car.hash} className=''>
                                        <CarMobileCard car={car} fnUpdate={() => setUpdateData(!updateData)} showPrice={!showroomMode} selectedCarId={selectedCarId} setSelectedCarId={setSelectedCarId}/>
                                    </div>
                                </div>
                            ))
                        }
                        <div className='w-full font-medium text-2xl text-center px-4'>
                            <div>{filterList(data).length === 0 ? 'No cars available with the selected filters' : ''}</div>
                        </div>
                    </div>

                    <div className="mb-8 mx-2">
                        <CustomerManagerInformationBox title='Need help?' className="shadow"/>
                    </div>

                    <div className="mb-[80px]">
                        <div className='flex items-center justify-center text-xs'>
                            <div className='mr-2 flex items-center'><VATIcon vatStatusId={4}/>&nbsp;&nbsp;VAT&nbsp;Included/Margin</div>
                            <div className='mr-2 flex items-center'><VATIcon vatStatusId={0}/>&nbsp;&nbsp;VAT&nbsp;Excluded/Net</div>
                        </div>
                    </div>

                    <div className='h-[100px]'></div>
                </div>
            </MobileLayout>
        )
    } else {
        return (
            <Waiting/>
        )
    }
}