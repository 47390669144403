import React, {useEffect, useState} from "react";
import {formatNumberWithSeparator} from "../../Hooks/UseGenericFunctions";
import {VATIcon} from "./VATIcon";
import {CarStatusPill} from "./CarStatusPill";
import {AddToBasketButton} from "../ShoppingBasket/AddToBasketButton";
import {CarDetailsMobile} from "./CarDetailsMobile";

export const CarMobileCard = ({car, fnUpdate, selectedCarId, setSelectedCarId, showPrice = true}) => {
    const [expandedView, setExpandedView] = useState(false);

    useEffect(() => {
        if (car.hash !== selectedCarId) {
            setExpandedView(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCarId]);

    useEffect(() => {
        if (car.hash === selectedCarId) {
            setExpandedView(true);
        }
    }, [selectedCarId]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="relative rounded-xl bg-white p-4 mb-4 shadow cursor-pointer">
            <div className="active:bg-blue-100" onClick={() => {
                setExpandedView(!expandedView);
                setSelectedCarId(car.hash)
            }}>
                <div className={'absolute pointer-events-none top-0 left-0 flex rounded-xl items-center justify-center bg-purchase-color opacity-20 w-full h-full ' + (car.in_basket ? ' block ' : ' hidden ')}>
                    <span className='text-3xl font-bold'></span>
                </div>
                <div className={'absolute top-0 left-0 flex rounded-lg items-center justify-center bg-white opacity-80 w-full h-full pointer-events-none ' + (car.is_sold === 1 ? ' block ' : ' hidden ')} onClick={(e) => e.stopPropagation()}>
                    <span className='text-3xl font-bold'>SOLD</span>
                    <span className="material-symbols-outlined text-[40px] ml-4">eco</span>
                </div>
                <div className={`absolute top-3 left-3 rounded-xl py-1 px-3 bg-purchase-color text-xs text-white ${car.in_basket !== null ? '' : 'hidden'}`}>
                    In Basket
                </div>
                <div className="w-full flex justify-center">
                    <img className='rounded-full w-[80px] h-[80px] bg-white object-scale-down shadow' src={car.list_image} alt={'image for ' + car.designation}/>
                </div>
                <div className={(car.buy_status === 'confirmed' && !car.is_sold ? '' : 'hidden') + ' absolute bottom-3 right-3'}>
                    <CarStatusPill title='Confirmed' bgColor='bg-confirmed' className='text-xs px-2'/>
                </div>
                <div className={(car.buy_status === 'fast_delivery' && !car.is_sold ? '' : 'hidden') + ' absolute bottom-3 right-3'}>
                    <CarStatusPill title='Fast Delivery' bgColor='bg-fast-delivery' className='text-xs px-2'/>
                </div>
                <div className={(car.buy_status === 'listing' && !car.is_sold ? '' : 'hidden') + ' absolute bottom-3 right-3'}>
                    <CarStatusPill title='Partner Listing' bgColor='bg-presales' className='text-xs px-2' foreColor="text-black"/>
                </div>
                <div className={(car.new_on_list === 'true' ? '' : 'hidden') + ' absolute left-3 bottom-3'}>
                    <CarStatusPill title='New' bgColor='bg-new-on-list' className='text-xs px-2'/>
                </div>
                <div className={(car.price_info !== "" && car.price_info !== undefined ? '' : 'hidden') + ' absolute left-3 bottom-3'}>
                    <CarStatusPill title={car.price_info} bgColor='bg-new-on-list' className='text-xs px-2'/>
                </div>

                <div className="flex justify-center font-bold px-8 text-center mt-3">{car.designation},&nbsp; {formatNumberWithSeparator(car.km, '.') + ' km'}</div>
                <div className="flex items-center justify-center font-bold text-xl text-purchase-color" style={{display: showPrice ? 'flex' : 'none'}}>
                    <div>{formatNumberWithSeparator(parseInt(car.price), '.')}&nbsp;{car.currency}</div>
                    <div className='flex items-center ml-2'><VATIcon vatStatusId={car.vat_status_id}/></div>
                </div>
                <div className="flex justify-center text-sm text-center mt-2">{car.info_line_1}</div>
                <div className="w-full flex justify-center mt-6">
                    <AddToBasketButton hash={car.hash} fnUpdate={fnUpdate} carInBasket={car.in_basket} className="w-[150px]" text="Add to basket"/>
                </div>
            </div>

            {/* EXPANDED VIEW */}
            {expandedView &&
                <CarDetailsMobile car={car} showPrice={showPrice} open={expandedView} selectedCarId={selectedCarId} />
            }
        </div>
    )
}