import React from "react";

export const SearchIcon = ({className}) => {
    return (
        <span className={`flex pl-3 items-center ${className} `}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                 className="w-6 h-6 text-white">
                <path strokeWidth="2" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                      d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"/>
            </svg>
        </span>
    )
}