import {useState} from "react";

export const PerformanceMonitor = () => {
    const [values, setValues] = useState({});
    const msLimit = 600;

    const endpoints = [
        { title: "Orders List", endpoint: "/shop/orders", unit: "ms" },
        { title: "Car List/Showroom", endpoint: "/shop/buy-list", unit: "ms" },
        { title: "MyCarzelle", endpoint: "/shop/my-site", unit: "ms" },
    ];

    const MonitorItem = ({title, value, endpoint, unit, onClick}) => {
        return (
            <div className="flex items-center justify-between p-4 w-full pr-12 bg-white rounded-xl shadow cursor-pointer" onClick={onClick}>
                <div className="mr-5">
                    <div className="text-gray-700 font-bold text-xl">{title}</div>
                    <div className="text-gray-500">{endpoint}</div>
                </div>
                <div className="text-center">
                    <div className={`${value > msLimit ? 'text-red-600' : ''} text-3xl font-semibold`}>{value}</div>
                    <div>{unit}</div>
                </div>
            </div>
        )
    }

    const fetchEndpoint = async (endpoint, key) => {
        const startTime = performance.now();
        try {
            const endTime = performance.now();
            const elapsed = Math.round(endTime - startTime);

            setValues((prevValues) => ({
                ...prevValues,
                [key]: elapsed,
            }));
        } catch (error) {
            console.error(`Error fetching data for ${endpoint}:`, error);
        }
    };

    const fetchAllSequentially = async () => {
        for (const item of endpoints) {
            await fetchEndpoint(item.endpoint, item.title);
        }
    };

    return (
        <div className="font-normal overflow-y-scroll h-screen p-12">
            <div className="rounded-xl mb-16 max-w-[500px]">
                {endpoints.map((item) => (
                    <div key={item.title} className="mb-4">
                        <MonitorItem
                            title={item.title}
                            endpoint={item.endpoint}
                            value={values[item.title]} // Get the value from the state
                            unit={item.unit}
                            onClick={() => fetchEndpoint(item.endpoint, item.title)}
                        />
                    </div>
                ))}
                <button
                    onClick={fetchAllSequentially}
                    className="mt-4 bg-blue-500 text-white py-2 px-4 rounded"
                >
                    Fetch All Sequentially
                </button>
            </div>
        </div>
    )
}