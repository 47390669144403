import {Terms} from "../../Terms/Terms";
import {MobileLayout} from "../../../Components/Layouts/MobileLayout";

export const MobileTerms = () => {
    return (
        <MobileLayout showroomMode={false} enableSearch={false} showSearch={false} setShowSearch={null} updateData={false} setUpdateData={null}>
            <div className="m-4 bg-white p-2 rounded-xl">
                <Terms/>
            </div>
        </MobileLayout>
    )
}