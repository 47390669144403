import {CloudArrowDownIcon, ComputerDesktopIcon} from "@heroicons/react/24/outline";
import {axiosInstance} from "../../Api/AxiosConfig";

export const DownloadButton = ({title, fileName, url, openInTab = true}) => {
    const downloadFile = (url) => {
        console.log(url);
        axiosInstance
            .get(url)

            .then((response) => {
                afterZipDownload(response.data);

            })
            .catch((error) => {
                console.error("Error fetching file:", error);
            });
    }
    
    function afterZipDownload(data) {
        var link = document.createElement("a");
        document.body.appendChild(link);
        link.setAttribute("type", "hidden");
        link.href = "data:text/plain;base64," + data.fileData;
        link.download = data.fileName;
        link.click();
        document.body.removeChild(link);
    }

    return (
        <>
            {openInTab &&
                <a href={url} target="_blank" rel="noopener noreferrer" className="flex items-center rounded-2xl bg-blue-400 pl-2 pr-3 py-1 cursor-pointer text-xs text-white capitalize whitespace-nowrap">
                    <div className="mr-2"><ComputerDesktopIcon className="w-4 h-4"/></div>
                    <div className="font-bold">{title}</div>
                </a>
            }
            {!openInTab &&
                <div onClick={(e) => downloadFile(url)} className="flex items-center rounded-2xl bg-blue-400 pl-2 pr-3 py-1 cursor-pointer text-xs text-white capitalize whitespace-nowrap">
                    <div className="mr-2"><CloudArrowDownIcon className="w-4 h-4"/></div>
                    <div className="font-bold">{title}</div>
                </div>
            }
        </>
    )
}