import {LeftNavigation} from "../../Components/Navigation/LeftNavigation";
import React, {useState} from "react";
import {Presales} from "../Presales/Presales";
import {Terms} from "../Terms/Terms";
import {useParams} from "react-router-dom";
import {AvailableCars} from "../AvailableCars";
import {Orders} from "../Orders/Orders";
import Pusher from "pusher-js";
import {getPusherCluster, getPusherKey} from "../../Hooks/UseBaseData";
import {useGlobalDataContext} from "../../GlobalState/GlobalDataProvider";
import {ExplanationModal} from "../../Components/System/Messages/ExplanationModal";
import {NewVersionAvailable} from "../../Components/System/Messages/NewVersionAvailable";
import {useDisclosure} from "@mantine/hooks";
import {MyCarzelle} from "../MyCarzelle/MyCarzelle";
import {DashboardPagesEnum} from "../../Enums/DashboardPagesEnum";

export const Dashboard = () => {
    const [updateData, setUpdateData] = useState(false);
    const {basketData, fetchGlobalData, showroomMode} = useGlobalDataContext();
    const {menuItem} = useParams();
    const [opened, {open, close}] = useDisclosure(false);

    const pusher = new Pusher(getPusherKey(), {
        cluster: getPusherCluster(),
    });

    let channel = pusher.subscribe('trade');
    channel.bind('carUpdate', function (data) {
        setUpdateData(!updateData);
    });
    channel.bind('newVersion', function (data) {
        open();
    });

    return (
        <div className="min-h-screen">
            {/*<ScreenSizeIndicator/>*/}
            <div className="hidden screen-small:block">
                <LeftNavigation chosenMenuItemId={menuItem} showroomMode={showroomMode}/>
            </div>

            <div className="screen-small:pb-4 screen-small:pl-4 screen-small:ml-14">
                {menuItem === DashboardPagesEnum.mycarzelle && <div className="ml-10 mt-10 text-4xl font-bold"><MyCarzelle selected={menuItem === DashboardPagesEnum.mycarzelle}/></div>}
                {menuItem === DashboardPagesEnum.showroom && <div><AvailableCars fnUpdate={fetchGlobalData} basketData={basketData} showSearch={true} showroomMode={false} selected={menuItem === DashboardPagesEnum.showroom}/></div>}
                {menuItem === DashboardPagesEnum.carlist && <div>
                    <div className="screen-small:block"><Presales fnUpdateGlobalData={fetchGlobalData} pushUpdate={updateData}/></div>
                </div>}
                {menuItem === DashboardPagesEnum.orderview && <div className="h-full max-h-screen overflow-auto"><Orders selected={menuItem === DashboardPagesEnum.orderview}/></div>}
                {menuItem === DashboardPagesEnum.terms && <div className="h-full max-h-screen overflow-auto"><Terms selected={menuItem === DashboardPagesEnum.terms}/></div>}
            </div>

            <ExplanationModal HelpComponent={<NewVersionAvailable/>} opened={opened} close={close} title="New version"/>
        </div>
    )
}