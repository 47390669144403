import {OkButton} from "../Buttons/OkButton";

export const NewVersionAvailable = () => {
    return (
        <div className="relative min-h-[400px] flex items-center justify-center">
            <div className="text-center">
                <div className="text-4xl font-bold">New version available!</div>
                <OkButton title="Click here to reload page" onClick={() => window.location.reload(true)} className="mt-8"/>
            </div>
        </div>
    )
}