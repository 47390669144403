import React from 'react';

export const TopMenuItem = ({ path, title, selected, width, number, spaceAfter, textColorClass }) => {
    return (
        <>
            <a href={path} className={'flex items-center relative justify-center hover:bg-white hover:scale-105 hover:opacity-80 duration-300 px-3 py-2 ' + (selected? 'font-bold py-2 border-b-4 border-b-blue-500 ' : 'border-b-2 border-b-slate-300')} style={{width: width, marginRight:  spaceAfter}}>
                <span className={`text-sm ${textColorClass} hover:text-black uppercase whitespace-nowrap`}>{title}</span>
                <div className={'absolute top-[-5px] right-[-5px] rounded-full w-[20px] h-[20px] flex items-center justify-center text-xs text-white ' + (number === undefined ? 'hidden' : 'bg-blue-500')}>
                    {number}
                </div>
            </a>
        </>
    )
}
