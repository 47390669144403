import React, {useEffect, useState} from "react";
import {ShoppingBasketMobile} from "../../Pages/Mobile/ShoppingBasket/ShoppingBasketMobile";
import {useGlobalDataContext} from "../../GlobalState/GlobalDataProvider";
import {Waiting} from "../System/Waiting";
import {MobileHeader} from "../../Pages/Mobile/General/MobileHeader";


export const MobileLayout = ({children, showroomMode, enableSearch, showSearch, setShowSearch, updateData, setUpdateData}) => {
    const [basketVisible, setBasketVisible] = React.useState(false);
    const {basketData, fetchGlobalData} = useGlobalDataContext();
    const [selectedMenuItem, setSelectedMenuItem] = useState(1);

    useEffect(() => {
        fetchGlobalData();
    }, [updateData]); // eslint-disable-line react-hooks/exhaustive-deps

    if (basketData) {
        return (
            <>
                <div className="relative max-h-screen overflow-y-scroll">
                    <div className="sticky top-0 left-0 z-[15]">
                        <MobileHeader showroomMode={showroomMode} enableSearch={enableSearch} showSearch={showSearch} setShowSearch={setShowSearch} basketVisible={basketVisible} setBasketVisible={setBasketVisible} selectedMenuItem={selectedMenuItem} setSelectedMenuItem={setSelectedMenuItem}/>
                        <div className="fixed left-0 top-0 z-[20] min-h-[200px]" style={{display: basketVisible ? 'block' : 'none'}}>
                            <div className="flex justify-center">
                                <ShoppingBasketMobile basketData={basketData} showPrice={true} fnUpdate={() => setUpdateData(!updateData)} showShadow={true} close={() => setBasketVisible(false)}/>
                            </div>
                        </div>
                    </div>
                    {children}
                </div>
            </>
        )
    } else {
        return (<Waiting/>)
    }
}