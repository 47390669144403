import {BrandPillSelection} from "./BrandPillSelection";
import {ValueRange} from "./ValueRange";
import {FilterHeader} from "./FilterHeader";
import React, {useState} from "react";
import {CarSearchBox} from "./CarSearchBox";
import {Switch} from "@mantine/core";
import {FuelSelection} from "./FuelSelection";
import {ShoppingBagIcon, BoltIcon, HeartIcon, BanknotesIcon} from "@heroicons/react/24/outline";
import {DownloadCarListInExcelFormat} from "../../Components/Excel/DownloadCarListInExcelFormat";
import {CarTypeSelection} from "./CarTypeSelection";

export const CarFilter = ({fnFilterChange, filterData, makes, fuels, carTypes, minKm, maxKm, minDamageAmount, maxDamageAmount}) => {
    const [selectedBrandIds, setSelectedBrandIds] = useState([]);
    const [selectedFuelsIds, setSelectedFuelsIds] = useState([]);
    const [selectedCarTypeIds, setSelectedCarTypeIds] = useState([]);
    const [, setSelectedPriceRange] = useState(['', '']);
    const [, setSelectedKmRange] = useState([minKm, maxKm]);
    const [, setSelectedDamageAmountRange] = useState([minDamageAmount, maxDamageAmount]);
    const [liked, setLiked] = useState(false);
    const [inBasket, setInBasket] = useState(false);
    const [openOffers, setOpenOffers] = useState(false);
    const [fastDelivery, setFastDelivery] = useState(false);
    const [resetFilter, setResetFilter] = useState(false);
    const [searchText, setSearchText] = useState('');

    const changeBrandPillSelection = (value) => {
        setSelectedBrandIds(value);
        fnFilterChange(prevState => ({
            ...prevState, make_ids: value
        }));
    }

    const changeCarTypeSelection = (value) => {
        setSelectedCarTypeIds(value);
        fnFilterChange(prevState => ({
            ...prevState, car_type_ids: value
        }));
    }

    const changeFuelsSelection = (value) => {
        setSelectedFuelsIds(value);
        fnFilterChange(prevState => ({
            ...prevState, fuel_ids: value
        }));
    }

    const changeLikeSelection = (value) => {
        fnFilterChange(prevState => ({
            ...prevState, liked: value
        }));
    }

    const changeInBasket = (value) => {
        fnFilterChange(prevState => ({
            ...prevState, in_basket: value
        }));
    }

    const changeOpenOffers = (value) => {
        fnFilterChange(prevState => ({
            ...prevState, open_offers: value
        }));
    }

    const changeFastDelivery = (value) => {
        fnFilterChange(prevState => ({
            ...prevState, fast_delivery: value
        }));
    }

    const changePriceRange = (value) => {
        setSelectedPriceRange([value.minValue, value.maxValue]);
        fnFilterChange(prevState => ({
            ...prevState, min_price: value.minValue, max_price: value.maxValue
        }));
    }

    const changeKmRange = (value) => {
        setSelectedKmRange([value.minValue, value.maxValue]);
        fnFilterChange(prevState => ({
            ...prevState, min_km: value.minValue, max_km: value.maxValue
        }));
    }

    const changeDamageAmountRange = (value) => {
        setSelectedDamageAmountRange([value.minValue, value.maxValue]);
        fnFilterChange(prevState => ({
            ...prevState, min_damage_amount: value.minValue, max_damage_amount: value.maxValue
        }));
    }

    const changeSearchText = (value) => {
        setSearchText(value);
        fnFilterChange(prevState => ({
            ...prevState, search_text: value
        }));
    }

    const clearFilter = () => {
        fnFilterChange(InitControlValueData);
        setLiked(false);
        setInBasket(false);
        setOpenOffers(false);
        setFastDelivery(false);
        changeDamageAmountRange({minValue: minDamageAmount, maxValue: maxDamageAmount});
        changeSearchText('');
        setSelectedCarTypeIds([]);
        setSelectedBrandIds([]);
        setSelectedFuelsIds([]);
        setResetFilter(!resetFilter);
    }

    const handleClearFilterClick = () => {
        // window.gtag('event', 'button_click', {
        //     event_category: 'User Interaction',
        //     event_label: 'Clear Filter On Car List',
        //     value: 1, // Optional numeric value for the event
        // });
        if (typeof window.gtag === 'function') {
            console.log('Sending button_click event to GA4');
            window.gtag('event', 'Clear Filter Pressed On Carlist', {
                event_category: 'Carlist',
                event_label: 'Clear Filter On Carlist',
                value: 1,
                debug_mode: true
            });
        } else {
            console.warn('Google Analytics is not initialized');
        }
        clearFilter();
    }

    const isAnyFilterActive = () => {
        return (
            filterData.search_text !== '' ||
            filterData.make_ids.length > 0 ||
            filterData.fuel_ids.length > 0 ||
            filterData.car_type_ids.length > 0 ||
            filterData.min_price !== '' ||
            filterData.max_price !== '' ||
            filterData.min_km !== '' ||
            filterData.max_km !== '' ||
            filterData.min_damage_amount !== '' ||
            filterData.max_damage_amount !== '' ||
            filterData.liked ||
            filterData.in_basket ||
            filterData.open_offers ||
            filterData.fast_delivery
        );
    };


    return (
        <div className="relative w-full max-w-[350px] rounded-xl bg-gray-50 px-6 border border-gray-200 h-full pt-4 overflow-auto">
            <div>
                <CarSearchBox value={searchText} setValue={changeSearchText}/>
            </div>
            <div className="mt-4">
                <div className="flex items-center flex-wrap">
                    <div className="mr-4 h-[30px]">
                        <div className="flex items-center">
                            <div><HeartIcon className={`w-4 h-4 mr-2`}/></div>
                            <Switch checked={liked} label='Liked' classNames={{track: 'cursor-pointer'}} onChange={(event) => {
                                setLiked(event.currentTarget.checked);
                                changeLikeSelection(event.currentTarget.checked)
                            }} className='whitespace-nowrap'/>
                        </div>
                    </div>
                    <div className=" h-[30px]">
                        <div className="flex items-center">
                            <div><ShoppingBagIcon className={`w-4 h-4 mr-2`}/></div>
                            <Switch checked={inBasket} label='In Basket' classNames={{track: 'cursor-pointer'}} onChange={(event) => {
                                setInBasket(event.currentTarget.checked);
                                changeInBasket(event.currentTarget.checked)
                            }} className='whitespace-nowrap'/>
                        </div>
                    </div>
                    <div className=" h-[30px]">
                        <div className="flex items-center">
                            <div><BanknotesIcon className={`w-4 h-4 mr-2`}/></div>
                            <Switch checked={openOffers} label='Open Offers' classNames={{track: 'cursor-pointer'}} onChange={(event) => {
                                setOpenOffers(event.currentTarget.checked);
                                changeOpenOffers(event.currentTarget.checked)
                            }} className='whitespace-nowrap'/>
                        </div>
                    </div>
                    <div className="h-[30px]">
                        <div className="flex items-center">
                            <div><BoltIcon className={`w-4 h-4 mr-2`}/></div>
                            <Switch checked={fastDelivery} label='Fast Delivery' classNames={{track: 'cursor-pointer'}} onChange={(event) => {
                                setFastDelivery(event.currentTarget.checked);
                                changeFastDelivery(event.currentTarget.checked)
                            }} className='whitespace-nowrap'/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-3">
                <FilterHeader title="Car Type" className="mb-2"/>
                <CarTypeSelection carTypes={carTypes} selectedCarTypeIds={selectedCarTypeIds} onFilterChange={changeCarTypeSelection}/>
            </div>

            <div className="mt-3">
                <FilterHeader title="Fuel Type" className="mb-2"/>
                <FuelSelection fuels={fuels} selectedFuelsIds={selectedFuelsIds} onFilterChange={changeFuelsSelection}/>
            </div>

            <div className="mt-3">
                <FilterHeader title="Make" className="mb-2"/>
                <BrandPillSelection makes={makes} selectedBrandIds={selectedBrandIds} onFilterChange={changeBrandPillSelection}/>
            </div>
            <div className="mt-3">
                <FilterHeader title="Price, Milage & Damage range" className="mb-2"/>
                <div className="mb-1"><ValueRange title="Price (x1000)" valueStep={10} onFilterChange={changePriceRange} resetFilter={resetFilter}/></div>
                <div className="mb-1"><ValueRange title="Km (x1000)" valueStep={1} onFilterChange={changeKmRange} resetFilter={resetFilter}/></div>
                <div className="mb-1"><ValueRange title="Damage (x100)" valueStep={1} onFilterChange={changeDamageAmountRange} resetFilter={resetFilter}/></div>
            </div>
            <div className="mt-12">
                <button className={`${isAnyFilterActive() ? 'bg-blue-500' : 'bg-gray-300'} rounded-xl text-white p-2 w-full`} onClick={handleClearFilterClick}>Clear filter</button>
                <div className="mt-8 pb-4">
                    <DownloadCarListInExcelFormat/>
                </div>
            </div>
        </div>
    )
}

export const InitControlValueData = () => {
    return {
        search_text: '',
        hide_sold: false,
        min_price: '',
        max_price: '',
        min_km: '',
        max_km: '',
        min_damage_amount: '',
        max_damage_amount: '',
        liked: false,
        in_basket: false,
        open_offers: false,
        fast_delivery: false,
        make_ids: [],
        fuel_ids: [],
        car_type_ids: [],
    }
}