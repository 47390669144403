import React from "react";
import {SearchIcon} from "../../../Components/Icons/SearchIcon";
import {getPartnerInfoLogo, getUserCompanyName} from "../../../Hooks/UseBaseData";
import {Burger, Menu} from "@mantine/core";
import {TopMenuItem} from "../../../Components/Navigation/TopMenuItem";
import {useDisclosure} from "@mantine/hooks";
import {useGlobalDataContext} from "../../../GlobalState/GlobalDataProvider";
import {useLocation} from "react-router-dom";

export const MobileHeader = ({showroomMode, enableSearch, showSearch, setShowSearch, basketVisible, setBasketVisible, hideBasketIcon = false}) => {
    const [opened, {toggle}] = useDisclosure();
    const {basketData, } = useGlobalDataContext();
    const {pathname} = useLocation();

    const menuItems = [
        {id: 1, path: "/mobile", title: "Car list", iconFileName: "shopping_cart"},
        {id: 2, path: "/mobile/checkout", title: "Checkout", iconFileName: "truck"},
        {id: 4, path: "/mobile/terms", title: "Terms", iconFileName: "shield"},
        {id: 5, path: "/logout", title: "Logout", iconFileName: "shield"},
    ];

    const selectedMenuItem = menuItems.find((item) => item.path === pathname)?.id;

    return (
        <header className="relative pb-3 bg-gradient-to-r from-black to-black pl-4 pt-4 bg-gray-400 justify-between">
            <div className="flex items-center">
                <div className="flex mr-4">
                    <div>
                        <Menu onClose={toggle}>
                            <Menu.Target>
                                <Burger
                                    opened={opened}
                                    onClick={toggle}
                                    color="white"
                                    size="lg"
                                    aria-label="Toggle menu"
                                />
                            </Menu.Target>

                            <Menu.Dropdown>
                                <Menu.Label>Make a choice</Menu.Label>
                                {menuItems.map((item) => (
                                    <Menu.Item key={item.id}>
                                        <TopMenuItem
                                            path={item.path}
                                            title={item.title}
                                            iconFileName={item.iconFileName}
                                            onSelect={() => {
                                            }}
                                            selected={selectedMenuItem === item.id}
                                            width="80px"
                                            spaceAfter="20px"
                                        />
                                    </Menu.Item>
                                ))}
                            </Menu.Dropdown>
                        </Menu>
                    </div>
                </div>
                <div>
                    {!hideBasketIcon &&
                        <div className="absolute right-6 top-6 text-white cursor-pointer" onClick={() => setBasketVisible(!basketVisible)}>
                            <div className={"flex material-symbols-outlined text-[30px] font-light "}>
                                shopping_cart
                            </div>
                            {basketData.basket_lines.length > 0 &&
                                <div className="absolute top-[-5px] right-[-5px] rounded-full flex items-center justify-center text-sm w-5 h-5 bg-white text-gray-500">
                                    {basketData.basket_lines.length}
                                </div>
                            }
                        </div>
                    }

                    <div>
                        {!showroomMode &&
                            <div className="mr-3 ml-0">
                                <a href='/'>
                                    <img src="/images/logo-white.png" className="min-h-[36px] max-h-[36px]" alt='Carzelle logo'/>
                                </a>
                            </div>
                        }

                        {getPartnerInfoLogo() === 'null' &&
                            <div className="mt-2 text-xl text-white font-light">
                                {getUserCompanyName()}
                            </div>
                        }
                    </div>

                    {getPartnerInfoLogo() !== 'null' &&
                        <div className="flex items-center">
                            <div className={`flex items-center`}>
                                <img src={getPartnerInfoLogo()} className="min-h-[36px] max-h-[36px]" alt='Partner logo'/>
                            </div>
                            <div className='text-xl text-white font-light border-l-2 border-l-white ml-2 pl-4'>
                                {getUserCompanyName()}
                            </div>
                        </div>
                    }
                </div>
            </div>

            {enableSearch &&
                <div className="absolute right-6 bottom-3 text-right" onClick={() => setShowSearch(!showSearch)}>
                    <SearchIcon/>
                </div>
            }
        </header>
    )
}