import {formatNumberWithSeparator} from "../../../Hooks/UseGenericFunctions";
import React, {useEffect, useState} from "react";
import {useGlobalDataContext} from "../../../GlobalState/GlobalDataProvider";
import {axiosInstance} from "../../../Api/AxiosConfig";
import {SoldPill} from "../../../Components/Information/SoldPill";
import {PurchaseTypePill} from "../../../Components/Information/PurchaseTypePill";
import {RemoveOfferFromBasketButton} from "../../../Components/ShoppingBasket/RemoveOfferFromBasketButton";
import {PendingActionWithText} from "../../../Components/System/PendingActionWithText";
import {Checkbox} from "@mantine/core";
import {ExplanationModal} from "../../../Components/System/Messages/ExplanationModal";
import {Terms} from "../../Terms/Terms";
import {ShortMessageModal} from "../../../Components/System/Messages/ShortMessageModal";
import {useDisclosure} from "@mantine/hooks";
import {MobileLayout} from "../../../Components/Layouts/MobileLayout";

export const CheckoutMobile = () => {
    const {globalData, fetchGlobalData} = useGlobalDataContext();
    const [, setShowConfirmSection] = useState(false);
    const [offerCars, setOfferCars] = useState([]);
    const [purchaseNowCars, setPurchaseNowCars] = useState([]);
    const [currentError, setCurrentError] = useState(null);
    const [cars, setCars] = useState([]);
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [, setShowErrorOverlay] = useState(false);
    const [opened, {close}] = useDisclosure(false);
    const [opened2, {open: openAcceptanceMessage, close: closeAcceptanceMessage}] = useDisclosure(false);
    const [orderCheckoutPending, setOrderCheckoutPending] = useState(false);

    const placeOrder = () => {
        if (!termsAccepted) {
            openAcceptanceMessage();
            return;
        }
        if (termsAccepted && !orderCheckoutPending) {
            axiosInstance
                .post("shop/confirm-basket")

                .then((response) => {
                    setOrderCheckoutPending(false);
                    console.log(response);
                    if (response.status === 200 && response.data.error === false) {
                        setTermsAccepted(false);
                        setCurrentError(null);
                        setShowConfirmSection(false);
                        fetchGlobalData();
                    }
                    if (response.status === 200 && response.data.error === true) {
                        setCurrentError(response.data.messages);
                    }
                })

                .catch((error) => {
                    setOrderCheckoutPending(false);
                    console.error("Error fetching data:", error);
                });
        }
        setOrderCheckoutPending(true);
    }

    const PurchaseTypeHeader = ({title}) => {
        return (<div className="text-xl font-bold mb-4 text-gray-600">{title}</div>);
    }

    useEffect(() => {
        fetchGlobalData();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setCars(globalData?.basket?.basket_lines);
    }, [globalData]);

    useEffect(() => {
        if (cars) {
            setOfferCars(cars.filter(car => car.offer_amount !== null));
            setPurchaseNowCars(cars.filter(car => car.offer_amount === null));
            setCurrentError(null);
        }
    }, [cars]);

    useEffect(() => {
        setShowErrorOverlay(currentError !== null);
    }, [currentError]);

    let carNumberTextOffers = offerCars.length > 1 ? 'cars' : 'car';
    let carNumberTextPurchaseNow = purchaseNowCars.length > 1 ? 'cars' : 'car';

    let confirmButtonLabel = '';
    if (offerCars.length > 0 && purchaseNowCars.length > 0) {
        confirmButtonLabel = 'Purchase ' + purchaseNowCars.length + ' ' + carNumberTextPurchaseNow + ' and make offer on ' + offerCars.length + ' ' + carNumberTextOffers;
    }

    if (offerCars.length > 0 && purchaseNowCars.length === 0) {
        confirmButtonLabel = 'Make offer on ' + offerCars.length + ' ' + carNumberTextOffers;
    }

    if (offerCars.length === 0 && purchaseNowCars.length > 0) {
        confirmButtonLabel = 'Purchase ' + purchaseNowCars.length + ' ' + carNumberTextPurchaseNow;
    }

    const BasketLine = ({car, buyNow}) => {
        return (
            <div key={car.cars_hash} className="relative flex items-start justify-between mb-2">
                <div className="flex w-full">
                    <div className={`${car.is_sold === 1 ? 'line-through' : ''} mr-2 `}>
                        #{car.cars_id}
                    </div>
                    <div className={`${car.is_sold === 1 ? 'line-through' : ''} flex-[3] `}>
                        {car.cars_designation}
                    </div>
                    <div className="flex justify-end mr-2 flex-1">
                        {car.is_sold === 1 &&
                            <SoldPill/>
                        }
                        {car.is_sold === null &&
                            <PurchaseTypePill car={car} className="px-1 text-2xl"/>
                        }
                    </div>
                </div>
                <div className={`${car.is_sold === 1 ? 'line-through' : ''} w-1/4 text-right`}>
                    {formatNumberWithSeparator(Number(buyNow ? car.price : car.offer_amount), ".")}&nbsp;{car.currency}
                </div>
                <RemoveOfferFromBasketButton offer_amount={car.offer_amount} basketHash={car.cars_hash} allowNonOfferRemoval={true} fnCallAfterRemoved={fetchGlobalData}/>
            </div>
        )
    }

    if (cars) {
        return (
            <MobileLayout showroomMode={false} enableSearch={false} showSearch={false} setShowSearch={null} updateData={false} setUpdateData={null}>
                <div className="relative">
                    <div id="shopping_bag" className="flex items-center justify-center text-gray-800">
                        <PendingActionWithText title="Placing your order" visible={orderCheckoutPending}/>
                        <div className="p-4 relative">
                            {cars.length > 0 &&
                                <div>
                                    <div className="flex items-center mb-4">
                                        <div className={"flex material-symbols-outlined text-[30px] font-light mr-2"}>
                                            shopping_cart
                                        </div>
                                        <div className="font-bold text-2xl whitespace-nowrap">Basket content</div>
                                    </div>
                                    <div className="text-sm bg-white rounded-xl p-4">
                                        <div className={`${purchaseNowCars.length > 0 ? '' : 'hidden'}`}>
                                            <PurchaseTypeHeader title="Purchases"/>
                                            {purchaseNowCars.map((car) => (
                                                <BasketLine key={car.basketHash} car={car} buyNow={true}/>
                                            ))}
                                        </div>

                                        <div className={`mt-6 ${offerCars.length > 0 ? '' : 'hidden'}`}>
                                            <PurchaseTypeHeader title="Offers"/>
                                            {offerCars.map((car) => (
                                                <BasketLine key={car.basketHash} car={car} buyNow={false}/>
                                            ))}
                                        </div>

                                        <div className="flex items-center justify-between font-bold mt-4 text-base">
                                            <div>Total</div>
                                            <div>{formatNumberWithSeparator(globalData.basket.basket_totals.amount, ".")}&nbsp;{globalData.basket.basket_totals.currency}</div>
                                        </div>
                                        <div className={`${currentError ? 'visible' : 'hidden'} mt-4 text-red-500 text-sm`}>
                                            <div className="flex items-start justify-between">
                                                <div className="bg-red-400 px-2 py-1 rounded mr-4 text-white whitespace-nowrap">
                                                    Your basket wasn't submitted!
                                                </div>
                                                <div>{currentError}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-full mt-6" onClick={(e) => e.stopPropagation()}>
                                        <div className="text-center">
                                            <button className={`${termsAccepted ? 'bg-blue-500' : 'bg-gray-400'} font-semibold px-6 py-3 text-white text-sm rounded grow-on-hover-5 shadow`} onClick={placeOrder}>{confirmButtonLabel}</button>
                                        </div>
                                        <div className="mr-4 flex items-center mt-4 w-full justify-center">
                                            <div className="flex items-center">
                                                <div className="mr-2">
                                                    <Checkbox label="" styles={{label: {fontSize: '12px'}}} value={termsAccepted} onChange={(e) => setTermsAccepted(e.currentTarget.checked)}/>
                                                </div>
                                                <div className="text-xs">
                                                    <a href="/terms">I understand that my offers are binding and I agree to <span className="underline cursor-pointer">Carzelles terms</span></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                            {cars.length === 0 &&
                                <div className="text-center w-full text-2xl mt-[200px] px-8">
                                    <div>Your basket is empty</div>
                                    <div className="mt-4"><a href="/mobile" className="underline">Go to the Car List to add cars</a></div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <ExplanationModal HelpComponent={<Terms showBorders={false}/>} opened={opened} close={close} title="Terms"/>
                <ShortMessageModal message={<div>Please accept terms first</div>} close={closeAcceptanceMessage} opened={opened2}/>
            </MobileLayout>
        )
    }
}