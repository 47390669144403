export const MyCarzelleOpenOffers = ({cars}) => {
    const formatAmount = (amount) => {
        return new Intl.NumberFormat('de-DE').format(amount);
    };

    return (
        <>
            <div className="flex items-center border-b border-gray-200 py-2">
                <div className="whitespace-nowrap text-sm font-bold min-w-[80px]">
                    Inv. no.
                </div>
                <div className="whitespace-nowrap text-sm font-bold min-w-[190px]">
                    VIN
                </div>
                <div className="whitespace-nowrap text-sm font-bold min-w-[290px]">
                    Car
                </div>
                <div className="whitespace-nowrap text-sm font-bold min-w-[90px]">
                    Date
                </div>
                <div className="whitespace-nowrap text-sm font-bold min-w-[120px]">
                    Price
                </div>
                <div className="whitespace-nowrap text-sm font-bold min-w-[120px]">
                    Offer Price
                </div>
            </div>
            {cars.map((car) => (
                <div key={"invoice_list_" + car.car_vin_no} className="flex items-center border-b border-gray-200 py-2">
                    <div className="flex items-center text-gray-500">
                        <div className="text-sm w-[80px]">
                            {car.car_id}
                        </div>
                        <div className="text-sm w-[190px]">
                            {car.car_vin_no}
                        </div>
                        <div className="whitespace-nowrap text-sm pr-2 overflow-hidden min-w-[290px]">
                            {car.car_designation}
                        </div>
                        <div className="text-sm w-[90px]">
                            {car.offer_confirmed_at}
                        </div>
                        <div className="text-sm w-[120px]">
                            {formatAmount(car.price)}&nbsp;{car.currency}
                        </div>
                        <div className="text-sm w-[120px]">
                            {formatAmount(car.offer_price)}&nbsp;{car.offer_price_currency}
                        </div>
                    </div>
                </div>
            ))
            }
        </>
    )
}