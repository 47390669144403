import {DownloadButton} from "./DownloadButton";

export const MyCarzelleLatestPurchaseList = ({cars}) => {
    const formatAmount = (amount) => {
        return new Intl.NumberFormat('de-DE').format(amount);
    };

    return (
        <>
            <div className="flex items-center border-b border-gray-200 py-2">
                <div>
                    <div className="text-sm font-bold w-[60px]">

                    </div>
                </div>
                <div className="whitespace-nowrap text-sm font-bold min-w-[190px]">
                    VIN
                </div>
                <div className="ml-4">
                    <div className="text-sm font-bold w-[190px]">
                        Car
                    </div>
                </div>
                <div className="ml-4">
                    <div className="text-sm font-bold w-[60px] text-center">
                        Km
                    </div>
                </div>
                <div className="ml-4">
                    <div className="text-sm font-bold w-[80px] text-center">
                        Model Year
                    </div>
                </div>
                <div className="ml-4">
                    <div className="text-sm font-bold w-[90px]">
                        Amount
                    </div>
                </div>
                <div className="ml-4">
                    <div className="text-sm font-bold w-[140px] flex justify-center">

                    </div>
                </div>

            </div>
            {cars.map((car) => (
                <div key={"lastest_purchases_" + car.vin_no} className="flex items-center border-b border-gray-200 py-2">
                    <div className="flex items-center text-gray-500">
                        <div className="text-sm w-[60px]">
                            <img src={car.list_image} className="rounded-full w-10 h-10 object-cover" alt={car.designation}/>
                        </div>
                        <div className="text-sm w-[190px]">
                            {car.vin_no}
                        </div>
                        <div className="text-sm ml-4 w-[190px]">
                            {car.designation}
                        </div>
                        <div className="text-right">
                            <div className="text-sm ml-4 w-[60px]">
                                {car.km}
                            </div>
                        </div>
                        <div className="text-right">
                            <div className="text-sm ml-4 w-[60px]">
                                {car.reg_year}
                            </div>
                        </div>
                        <div className="text-right">
                            <div className="text-sm ml-4 w-[90px]">
                                {formatAmount(car.amount)}&nbsp;DKK
                            </div>
                        </div>
                        <div>
                            {car.image_download_link &&
                                <div className="text-sm ml-4 w-[180px] flex justify-center">
                                    <DownloadButton title="Download Images" url={car.image_download_link} openInTab={false}/>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            ))
            }
        </>
    )
}