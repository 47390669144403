import {HeartIcon as OutlineHeartIcon, LockClosedIcon} from "@heroicons/react/24/outline";
import {HeartIcon as SolidHeartIcon, ArrowDownIcon, StarIcon} from "@heroicons/react/24/solid";
import {axiosInstance} from "../../Api/AxiosConfig";
import React from "react";
import {formatNumberWithSeparator, isExpired} from "../../Hooks/UseGenericFunctions";
import {AddToBasketButton} from "../../Components/ShoppingBasket/AddToBasketButton";
import {DivCell} from "./DivCell";
import {PurchaseTypePill} from "../../Components/Information/PurchaseTypePill";
import {OfferInput} from "./OfferInput";
import {VATIcon} from "../../Components/Cars/VATIcon";
import {CarzelleTooltip} from "../../Components/System/Messages/CarzelleTooltip";
import {PartnerListingCountDown} from "./PartnerListingCountDown";

export const PresalesListTableRow = React.memo(({car, equipmentIcons, activeCarId, fnUpdateCarList, fnSetActiveCar}) => {
    function formatNumber(number = 'en-US') {
        return new Intl.NumberFormat('da-DK').format(Math.floor(number));
    }

    const addLike = (hash) => {
        axiosInstance
            .post("shop/like", {hash: hash})

            .then(() => {
                fnUpdateCarList();
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    }

    const removeLike = (hash) => {
        axiosInstance
            .delete("shop/like", {data: {hash: hash}})

            .then(() => {
                fnUpdateCarList();
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    }

    const handleRowClick = () => {
        fnSetActiveCar(car);
    }

    const getIconDetailsFromId = (id) => {
        const equipment = equipmentIcons.find(equipment => equipment.id === id);
        return {
            icon_url: equipment?.icon_url,
            title: equipment?.name,
        };
    };

    return (
        <div key={car.hash} onClick={handleRowClick} className={`flex items-center border-l-[5px] ${car.in_basket !== null ? 'border-purchase-color bg-gray-100 ' : activeCarId === car.hash ? 'border-l-gray-200 bg-gray-200' : 'border-l-white bg-white'} px-2 h-[41px] text-[12px] text-gray-500 cursor-pointer hover:bg-gray-100 hover:border-l-gray-100 hover:shadow transition duration-150 ease-in-out border-gray-100`}>
            <DivCell className="w-[45px]">
                {car.like &&
                    <div className="flex items-center justify-center" onClick={() => removeLike(car.hash)}><SolidHeartIcon className="w-4 h-4 text-gray-400"/></div>
                }
                {!car.like &&
                    <div className="flex items-center justify-center" onClick={() => addLike(car.hash)}><OutlineHeartIcon className="w-4 h-4 text-primary-color"/></div>
                }
            </DivCell>
            <DivCell className="w-[50px]">
                <div className="h-full w-[36px] relative">
                    <img id={"car_main_image_" + car.hash} className=' h-full my-1 object-scale-down rounded-full' src={car.list_image} alt={'image for ' + car.designation} loading="lazy"/>
                    {car.new_on_list === 'true' &&
                        <div className="absolute left-[-10px] top-[-0px]">
                            <CarzelleTooltip message="This car is new on the list">
                                <div className="rounded-full p-1 bg-green-600 text-white"><StarIcon className="w-3 h-3"/></div>
                            </CarzelleTooltip>
                        </div>
                    }
                </div>
            </DivCell>
            <DivCell className="w-[45px]">
                <div className="flex items-center">
                    #{car.car_id}
                </div>
            </DivCell>
            <DivCell className="w-[120px] max-w-[120px] 2xl:w-[250px] 2xl:max-w-[250px] relative text-nowrap overflow-hidden text-ellipsis pt-[2px] h-[22px]">
                <div className="mr-2">{car.designation}</div>
                {car.expires_at_rest !== "" &&
                    <div className="absolute right-0 top-[2px]">
                        <PartnerListingCountDown seconds={car.expires_at_rest}/>
                    </div>
                }
            </DivCell>
            <DivCell className="w-[130px] hidden 2xl:block">
                <PurchaseTypePill car={car}/>
            </DivCell>
            <DivCell className="w-[100px]">
                <span className="overflow-hidden overflow-ellipsis whitespace-nowrap text-right pr-4">
                    {car.reg_date_show}
                </span>
            </DivCell>
            <DivCell className="w-[65px]">
                <span className=" overflow-hidden overflow-ellipsis whitespace-nowrap flex justify-end pr-4">
                    {formatNumber(car.km)}
                </span>
            </DivCell>
            <DivCell className="w-[80px] hidden 2xl:block">
                {(Number(car.damage_amount) >= 0) &&
                    <span className="overflow-hidden overflow-ellipsis whitespace-nowrap pr-4 flex items-center justify-end">
                        <div>{formatNumberWithSeparator(Number(car.damage_amount), '.')}€</div>
                    </span>
                }
            </DivCell>
            <DivCell className="p-0 w-[160px]">
                <span className="overflow-hidden overflow-ellipsis whitespace-nowrap p-0">
                    <div className="flex items-center relative">
                        {car.price_info !== '' && !car.allow_offers &&
                            <div className="absolute left-0">
                                <CarzelleTooltip message={car.price_info}>
                                    <div className="rounded-full p-1 bg-green-600 text-white"><ArrowDownIcon className="w-3 h-3"/></div>
                                </CarzelleTooltip>
                            </div>
                        }
                        <div className="min-w-[100px] text-right">{formatNumber(car.price)}&nbsp;{car.currency}</div>
                        <div className='flex items-center ml-2'><VATIcon vatStatusId={car.vat_status_id}/></div>
                    </div>
                </span>
            </DivCell>
            <DivCell className="w-[75px] flex justify-start">
                {!isExpired(car.expires_at_rest) &&
                    <div className="overflow-hidden overflow-ellipsis whitespace-nowrap">
                        <AddToBasketButton hash={car.hash} fnUpdate={fnUpdateCarList} text={car.buy_status !== 'listing' ? "Buy now" : null} bgColor={`${car.allow_offers || car.buy_status !== 'listing' ? 'bg-purchase-color' : 'bg-presales'} `} carInBasket={car.in_basket} tooltip="You can add this car to your list for consideration, including it to those you want us to acquire for you."/>
                    </div>
                }
            </DivCell>
            <DivCell className="w-[120px]">
                {!isExpired(car.expires_at_rest) && !car.fixed_price &&
                    <div>
                        <OfferInput car={car} fnUpdate={fnUpdateCarList}/>
                    </div>
                }
                {car.fixed_price &&
                    <div className="flex items-center">
                        <div><LockClosedIcon className="w-4 h-4 text-gray-400 mr-2"/></div>
                        <div className="text-xs">Fixed price</div>
                    </div>
                }
            </DivCell>
            <DivCell className="w-[55px] text-right hidden screen-large:block">
                {car.horsepower}
            </DivCell>
            <DivCell className="w-[160px] hidden screen-large:block">
                <span className="block overflow-hidden overflow-ellipsis whitespace-nowrap">
                    <div>{car.color}</div>
                </span>
            </DivCell>
            <DivCell className="w-[160px] hidden screen-large:block">
                <span className="block overflow-hidden overflow-ellipsis whitespace-nowrap">
                    <div>{car.fuel_name}</div>
                </span>
            </DivCell>
            <DivCell className="w-[160px] hidden 4xl:block">
                <span className="block overflow-hidden overflow-ellipsis whitespace-nowrap">
                    <div>{car.gear_name}</div>
                </span>
            </DivCell>
            <DivCell className="hidden 4xl:block">
                <span className="block overflow-hidden overflow-ellipsis whitespace-nowrap">
                    {car.equipment_base_icons !== null &&
                        <div className="flex items-center">
                            {car.equipment_base_icons.split(',').map(Number).map((iconId) => {
                                const result = getIconDetailsFromId(iconId);
                                return (
                                    <div key={"car_icon_" + car.id + "_" + iconId} className="mr-1">
                                        <img className="w-4 h-4 opacity-50" src={result.icon_url} title={result.title} alt={result.title}/>
                                    </div>
                                )
                            })}
                        </div>
                    }
                </span>
            </DivCell>
        </div>
    )
});
